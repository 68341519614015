import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, tap } from "rxjs/operators";

export class ApiService<GetItemType, GetAllResponse>{

  private _http: HttpClient;
  public basePath = '';
  public entityName = '';


  private dataLoadEvent = new BehaviorSubject<GetAllResponse>(null);
  get onDataLoadEvent$(): Observable<GetAllResponse> {
    return this.dataLoadEvent.asObservable();
  }


  private itemLoadEvent = new BehaviorSubject<GetItemType>(null);
  get onItemLoadEvent$(): Observable<GetItemType> {
    return this.itemLoadEvent.asObservable();
  }


  constructor(
    http: HttpClient,
    basePath: string
  ) {
    this._http = http;
    this.basePath = basePath;
  }


  public setList(data: GetAllResponse){
    this.dataLoadEvent.next(data);
  }

  public clearList(){
    this.dataLoadEvent.next(null);
  }

  public setItem(item: GetItemType){
    this.itemLoadEvent.next(item);
  }


  public get(pach: string, params?: any): Promise<GetAllResponse> {

    return new Promise((resolve, reject) => {

      this._http.get<GetAllResponse>(this.basePath + (pach ? `/${pach}` : ''), {params: params}).subscribe(
        {
          next: (data) => {
            resolve(data);
            this.dataLoadEvent.next(data);
          },
          error: (e) => reject(e),
          complete: () => {}
        });


    });


  }

  public getById(id: string): Promise<GetItemType> {
    const url = `${this.basePath}/${id}`;

    return new Promise((resolve, reject) => {

      this._http.get<GetItemType>(url).subscribe(
        {
          next: (data) => {
            resolve(data);
            this.itemLoadEvent.next(data);
          },
          error: (e) => reject(e),
          complete: () => {}
        });

    });



  }


  public post(patch: string, body: any): Observable<any> {
    const url = `${this.basePath}${patch}`;
    return this._http.post<any>(url, body);
  }

  public edit(model: any): Observable<any> {
    return this._http.put<any>(this.basePath, model);
  }
}
