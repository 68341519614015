export const environment = {
  siteTitle: 'Hotéis SESC',
  production: true,
  redirectHome: 'site/home',

  distFolder: '../browser',
  basepath: '',

  houseId: '81f42a99-9123-4bf6-8a30-ac54240b65b5',
  appTargetId: '134317ac-cdfd-4e29-8c93-765d6f473d20',

  eventRegisteredStatusId: 'ecdf3fb7-5815-4296-a5de-7aaee519b99c',
  statusIdParticipated: 'C18FD6FB-560A-4F33-BAB1-3C4C57DA23B3',

  reCAPTCHA: "6Lfy0uQlAAAAAPmf0WUOHh6Fs-Agyw-ZJi8eI9ms",
  reCAPTCHA_Validate: true,
  googleAnalyticsId: "G-5MWKVDNCE8",
  urls: {
    bffUrl: 'https://api.senacrs.com.br/bff/hoteis/v1/api',
    filesUrl: 'https://api.senacrs.com.br/bff/hoteis/v1/file'
  }
};
